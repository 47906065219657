/* eslint-disable jsx-a11y/anchor-is-valid */
import { Dropdown } from "antd";
import { useAuth } from "lib/auth/exports";
import React from "react";
import { performLogin } from "shared/utils/common-methods";
import { Avatar, TopNavBar } from "syngenta-digital-cropwise-react-ui-kit";
import Logo from "../../assets/images/logo.svg";
import "./header.less";

const getInitials = (name: string) => {
  try {
    const firstName = name.split(" ")[0];
    const lastName = name.split(" ")[1] || " ";
    return `${firstName.split("")[0]}${lastName.split("")[0]}`.toUpperCase();
  } catch {
    return "NA";
  }
};

const ProfileOverlay = () => {
  const { user, logout, setLogoutStatus } = useAuth();
  return (
    <div data-cy="profileOverlay" className="logoutOverlayBlock">
      <div className="profileInfo">
        <h3>{"User Profile"}</h3>
        <div className="profileUserData">
          <span>{user.name}</span>
        </div>
      </div>
      <button
        className="logoutLink"
        onKeyDown={() => performLogin(setLogoutStatus, logout)}
        onClick={() => performLogin(setLogoutStatus, logout)}
      >
        <span>{"Log out"}</span>
      </button>
    </div>
  );
};

const Profile = (props: any) => {
  return (
    <Dropdown
      trigger={["click"]}
      overlay={<ProfileOverlay />}
      placement="topRight"
    >
      <a
        role="link"
        tabIndex={0}
        data-cy="profile-icon"
        className="ant-dropdown-link"
        onKeyDown={(e) => e.preventDefault()}
        onClick={(e) => e.preventDefault()}
      >
        <Avatar shape="circle" size={40}>
          {props.initials}
        </Avatar>
      </a>
    </Dropdown>
  );
};

function getNavBarIcon() {
  return (
    <div data-cy="navBarIcon" className="cropwise-sustainability--logo">
      <img src={Logo} alt="Cropwise Sustainability" />
    </div>
  );
}

export const GlobalHeader = () => {
  const { user } = useAuth();
  return (
    <TopNavBar
      navbarIcon={getNavBarIcon}
      newDesign={true}
      profileDropDown={
        <Profile initials={getInitials(user.name)} autoDestroy={true} />
      }
    />
  );
};
