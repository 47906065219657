import { Tooltip } from "antd";
import { CommonProps } from "modules/framework/types";
import React, { ReactNode } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumb, Layout } from "syngenta-digital-cropwise-react-ui-kit";
import { TEMPLATE_NAME } from "../../constants";

const renderBreadcrumbItems = (
  { innerModuleNames, outerModuleName, outerModulePath }: BreadcrumbProps,
  location: Location
) => {
  const StyledLink = styled(Link)`
    display: inline-block;
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 20px;
    cursor: pointer !important;
  `;
  let breadcrumbItems: any[] = [];
  if (innerModuleNames && Object.keys(innerModuleNames)?.length) {
    breadcrumbItems = Object.entries(innerModuleNames)?.map(
      ([key, value], index) => {
        const displayValue = TEMPLATE_NAME === value ? "SAIS" : value;

        const moduleKey = key.split(/(?=[A-Z])/)[0];
        const indexToAdd = moduleKey === "edit-framework" ? 2 : 3;
        function getLink() {
          const indexOfKey =
            location?.pathname.split("/").indexOf(moduleKey) + indexToAdd;
          const breadcrumbLink = location.pathname
            .split("/")
            .slice(0, indexOfKey)
            .join("/");
          return breadcrumbLink;
        }
        return (
          <Breadcrumb.Item key={key}>
            <Tooltip title={value} arrow={false}>
              <StyledLink
                to={getLink()}
                data-cy={`${moduleKey}-breadcrump-link-2`}
              >
                {displayValue}
              </StyledLink>
            </Tooltip>
          </Breadcrumb.Item>
        );
      }
    );
  }
  return (
    <>
      <Breadcrumb.Item key={outerModuleName}>
        <Link
          to={outerModulePath || ""}
          data-cy={`${outerModuleName?.toLocaleLowerCase()}-breadcrump-link`}
        >
          {outerModuleName}
        </Link>
      </Breadcrumb.Item>
      {breadcrumbItems}
    </>
  );
};

export const AppBreadcrumb: React.FC<BreadcrumbProps> = ({
  innerModuleNames,
  outerModuleName,
  outerModulePath,
}: BreadcrumbProps) => {
  const location = useLocation();
  return (
    <Breadcrumb>
      {renderBreadcrumbItems(
        { innerModuleNames, outerModuleName, outerModulePath },
        location
      )}
    </Breadcrumb>
  );
};

interface BreadcrumbProps {
  innerModuleNames?: CommonProps | {} | undefined;
  outerModuleName?: string;
  outerModulePath?: string;
}

interface AppWrapperProps extends Partial<BreadcrumbProps> {
  children?: ReactNode;
}

export const AppWrapper: React.FC<AppWrapperProps> = ({
  innerModuleNames,
  outerModuleName,
  outerModulePath,
  children,
}: AppWrapperProps) => {
  return (
    <>
      <AppBreadcrumb
        innerModuleNames={innerModuleNames}
        outerModuleName={outerModuleName}
        outerModulePath={outerModulePath}
      />
      <Layout className="cw-module-inner-display">{children}</Layout>
    </>
  );
};
