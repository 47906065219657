import {
  getIndexByID,
  isObjectEqual,
  openNotificationWithIcon,
} from "shared/utils/common-methods";

export const setQuestion = (labelToShow, value, form) => {
  form.setFieldsValue({ ...form.getFieldsValue(), [labelToShow]: value });
};

export const getSplittedKey = (key) => {
  return key.split(" ");
};

export const deleteYesNoQuestionSet = async ({
  deleteQuestion,
  deleteQuestionParams,
  errorMessage,
}) => {
  try {
    await deleteQuestion({ ...deleteQuestionParams });
  } catch (error) {
    openNotificationWithIcon({
      type: "error",
      message: errorMessage,
    });
    console.log(error);
  }
};

export const getParamsForDeletingQuestion = (props) => {
  return {
    titleText: "this question",
    content: "This action cannot be undone.",
    onOk: () => {
      deleteYesNoQuestionSet(props);
    },
  };
};

export const validateSoaId = (value, required = true) => {
  const valueWithoutEmptySpace = value?.trim();
  if (required && !valueWithoutEmptySpace) {
    return Promise.reject(new Error("SOA ID is required!"));
  }
  if (valueWithoutEmptySpace) {
    if (/^\b[A-Z]{2}\b-\b\d{1,2}\b$/.test(valueWithoutEmptySpace)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Please enter SOA ID in correct format!"));
  }
  return Promise.resolve();
};

export const addValidationRulesToForm = (isAnswer, key, fieldName) => {
  if (isAnswer) {
    if (key[1].includes("soa_id")) {
      return [
        {
          required: false,
          validator(_, value) {
            return validateSoaId(value, false);
          },
        },
      ];
    } else if (key[1].includes("label_id")) {
      return [{ required: false }];
    } else {
      return [
        {
          required: true,
          message: `You must enter an ${fieldName}`,
        },
      ];
    }
  } else {
    return [
      {
        required: true,
        message: `You must enter ${key}`,
      },
    ];
  }
};

export const getAnswerByKey = (name, answers) => {
  return answers?.[name[0]]?.[name[1]];
};

export const populateAnswerLabels = (answers, isEditQuestionPage) => {
  answers = answers.map((a) => ({ ...a, label_id: a.label_id || -999 }));
  return answers.reduce((acc, answer, index) => {
    function getAnswerLabelId() {
      if (!isEditQuestionPage) {
        return index + 1;
      }
      return answer["label_id"] !== -999
        ? answer["label_id"]
        : Math.max(...answers.map((a) => a.label_id)) + 1;
    }
    answer.label_id = getAnswerLabelId();
    let labels = {
      labelId: answer["label_id"],
      labelName: answer[`input`],
      soa_id: answer["soa_id"],
    };
    if (answer[`soa_id`]) {
      labels["soa_id"] = answer[`soa_id`];
    }
    acc = [...acc, { ...labels }];
    return acc;
  }, []);
};

export const getPayloadForUpdatingQuestion = (
  question_id,
  Question,
  questionType,
  answers,
  isEditQuestionPage,
  locale
) => {
  return {
    id: question_id || "",
    title: Question,
    type: questionType,
    locale,
    labels: populateAnswerLabels(answers, isEditQuestionPage),
  };
};

export const getPayloadForCreatingQuestion = (
  driver_id,
  frameworkId,
  Question,
  questionType,
  answers,
  locale
) => {
  return {
    locale,
    type: questionType,
    title: Question,
    driverIds: [parseInt(driver_id || "")],
    frameworkId,
    isPracticeQuestion: false,
    labels: populateAnswerLabels(answers),
  };
};

export const getUpdatedComponentData = (componentData, dataToBeUpdated) => {
  let componentName = Object.keys(componentData.pages[0])[0];
  return {
    ...componentData,
    pages: [
      {
        [`${componentName}`]: {
          ...componentData.pages[0][componentName],
          data: [...dataToBeUpdated],
        },
      },
    ],
  };
};

export const updateDataToStoreInCache = (
  indexToUpdateData,
  key,
  dataToUpdate,
  existingData
) => {
  if (
    dataToUpdate &&
    !isObjectEqual(dataToUpdate, existingData[indexToUpdateData][key])
  ) {
    existingData[indexToUpdateData] = {
      ...existingData[indexToUpdateData],
      [key]: dataToUpdate,
    };
  }
};

export const updateQuestionData = (
  dataRequiredToUpdateQuestion,
  existingQuestions
) => {
  const { title, id, type } = dataRequiredToUpdateQuestion;
  const index = getIndexByID(id, existingQuestions);
  if (index !== -1) {
    updateDataToStoreInCache(index, `title`, title, existingQuestions);
    updateDataToStoreInCache(index, `type`, type, existingQuestions);
  }
};

export const updateModuleCountOnCards = (driver_id, count, oldDriverData) => {
  let driver = { ...oldDriverData };
  let existingDrivers = driver.pages[0].drivers.data;
  existingDrivers = existingDrivers.map((item) => {
    if (item.id === +driver_id) {
      item.questionCount = item.questionCount + Number(count);
    }
    return item;
  });
  return getUpdatedComponentData(driver, existingDrivers);
};

export const removeQuestion = (existingData, id) => {
  return existingData.filter((item) => item.id !== id);
};
export const removeQuestionFromCache = (
  oldQuestionData,
  idToRemove,
  queryKey,
  queryClient,
  t,
  queryToExecute,
  message
) => {
  let question = { ...oldQuestionData };
  let existingQuestions = question?.pages[0]?.questions.data;
  question.pages[0].questions.meta.totalCount -= 1;
  existingQuestions = removeQuestion(existingQuestions, idToRemove);
  let updatedQuestions = getUpdatedComponentData(question, existingQuestions);
  queryClient.setQueryData(
    [queryToExecute, { query: { drivers: { id: Number(queryKey) } } }],
    updatedQuestions
  );
  openNotificationWithIcon({
    type: "success",
    message: t(message),
  });
};

export const addQuestionInCache = (
  oldQuestionData,
  queryClient,
  driver_id,
  t,
  createQuestionData
) => {
  let question = { ...oldQuestionData };
  let existingQuestions = question.pages[0].questions.data;
  question.pages[0].questions.meta.totalCount += 1;
  const { id: questionID, title, number, type } = createQuestionData;
  existingQuestions.push({ id: questionID, title, number, type });
  queryClient.setQueryData(
    ["questions.infinite", { query: { drivers: { id: Number(driver_id) } } }],
    getUpdatedComponentData(question, existingQuestions)
  );
  openNotificationWithIcon({
    type: "success",
    message: t(`Question created`),
  });
};
export const updateQuestionList = (queryClient, t, message, queryName) => {
  queryClient.invalidateQueries(queryName).then(() => {
    setTimeout(() => {
      openNotificationWithIcon({
        type: "success",
        message: t(message),
      });
    }, 2000);
  });
};

export const createQuestionSuccessCallback = (
  queryClient,
  driver_id,
  t,
  createQuestionData
) => {
  const oldQuestionData = queryClient.getQueryData([
    "questions.infinite",
    { query: { drivers: { id: Number(driver_id) } } },
  ]);
  if (oldQuestionData) {
    addQuestionInCache(
      oldQuestionData,
      queryClient,
      driver_id,
      t,
      createQuestionData
    );
  } else {
    //Below else statement code will execute when user refreshes the page and no data is present in cache
    updateQuestionList(
      queryClient,
      t,
      "Question created",
      "questions.infinite"
    );
  }
};

export const updateQuestionSuccessCallback = (
  queryClient,
  driver_id,
  dataRequiredToUpdateQuestion,
  t
) => {
  const oldQuestionData = queryClient.getQueryData([
    "questions.infinite",
    { query: { drivers: { id: Number(driver_id) } } },
  ]);
  if (oldQuestionData) {
    updateQuestionInCache(
      oldQuestionData,
      dataRequiredToUpdateQuestion,
      driver_id,
      queryClient,
      t
    );
  } else {
    //Below else statement code will execute when user refreshes the page and no data is present in cache
    updateQuestionList(
      queryClient,
      t,
      "Question updated",
      "questions.infinite"
    );
  }
};

export const updateQuestionInCache = (
  oldQuestionData,
  dataRequiredToUpdateQuestion,
  driver_id,
  queryClient,
  t
) => {
  let question = { ...oldQuestionData };
  let existingQuestions = question.pages[0].questions.data;
  updateQuestionData(dataRequiredToUpdateQuestion, existingQuestions);
  queryClient.setQueryData(
    ["questions.infinite", { query: { drivers: { id: Number(driver_id) } } }],
    getUpdatedComponentData(question, existingQuestions)
  );
  openNotificationWithIcon({
    type: "success",
    message: t(`Question updated`),
  });
};
